import React from "react";
import { styled, Fade, Box, LinearProgress, linearProgressClasses } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/icons/logo-color.svg";

const Container = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "0",
  left: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
  backgroundColor: "#FFFFFF",
  zIndex: "1600",
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 10,
  marginTop: "20px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#F0F0F0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: "linear-gradient(262deg, #8C72FF 0%, #C057EF 47%, #F0749B 100%)",
  },
}));

const Loader = ({ isLoading }) => {
  return (
    <Fade in={isLoading}>
      <Container>
        <Box width='200px'>
          <Logo />
          <BorderLinearProgress />
        </Box>
      </Container>
    </Fade>
  );
};

export default Loader;
