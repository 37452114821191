import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <React.Fragment>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          closeOnClick
          // pauseOnHover
          theme="light"
          hideProgressBar={true}
        />
        <Router />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
