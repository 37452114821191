import { lazy } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Dashboard = lazy(() => import("../pages/admin/Dashboard"));
const BusinessDashboard = lazy(() => import("../pages/business_owner/Dashboard"));
const Employee = lazy(() => import("../pages/business_owner/Employee"));
const Company = lazy(() => import("../pages/business_owner/Company"));
const CardAddOns = lazy(() => import("../pages/admin/CardAddOns/index"));
const Configuration = lazy(() => import("../pages/admin/Configuration/index"));
const DirectOrder = lazy(() => import("../pages/admin/DirectOrder/index"));
const Packages = lazy(() => import("../pages/admin/Packages/index"));
const Payments = lazy(() => import("../pages/admin/Payments/index"));
const BusinessPayments = lazy(() => import("../pages/business_owner/Payments"));
const Payouts = lazy(() => import("../pages/admin/Payouts/index"));
const Settings = lazy(() => import("../pages/common/Settings/index"));
const BusinessSettings = lazy(() => import("../pages/business_owner/Settings/index"));
const ShippingOrder = lazy(() => import("../pages/admin/ShippingOrder/index"));
const TeamUsers = lazy(() => import("../pages/admin/TeamUsers/index"));
const CreateUser = lazy(() => import("../pages/support/CreateUser"));
const BusinessOwner = lazy(() => import("../pages/admin/BusinessOwner"));
const ReviewUser = lazy(() => import("../pages/admin/ReviewUser"));
const CreateBusinessOwner = lazy(() => import("../pages/admin/CreateBusinessOwner"));
const CreateReviewUser = lazy(() => import("../pages/admin/CreateReviewUser"));
const Users = lazy(() => import("../pages/common/Users/index"));
const EditPersonalProfile = lazy(() => import("../pages/common/Users/EditPersonalProfile"));
const EditProfile = lazy(() => import("../pages/common/Users/EditProfile"));

const privateRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    users: ["admin"],
  },
  {
    path: "/dashboard",
    element: <BusinessDashboard />,
    users: ["business_owner"],
  },
  {
    path: "/employee",
    element: <GoogleReCaptchaProvider
      reCaptchaKey={ process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY }
    >
      <Employee />
    </GoogleReCaptchaProvider>,
    users: ["business_owner"],
  },
  {
    path: "/company",
    element: <Company />,
    users: ["business_owner"],
  },
  {
    path: "/card-add-ons",
    element: <CardAddOns />,
    users: ["admin"],
  },
  {
    path: "/configuration",
    element: <Configuration />,
    users: ["admin"],
  },
  {
    path: "/order/direct-order",
    element: <DirectOrder />,
    users: ["admin"],
  },
  {
    path: "/packages",
    element: <Packages />,
    users: ["admin"],
  },
  {
    path: "/payments",
    element: <BusinessPayments />,
    users: ["business_owner"],
  },
  {
    path: "/payments",
    element: <Payments />,
    users: ["admin"],
  },
  {
    path: "/payouts",
    element: <Payouts />,
    users: ["admin"],
  },
  {
    path: "/order/shipping-order",
    element: <ShippingOrder />,
    users: ["admin"],
  },
  {
    path: "/team-users",
    element: <TeamUsers />,
    users: ["admin"],
  },
  {
    path: "/edit-personal",
    element: <EditPersonalProfile />,
    users: ["admin", "support", "business_owner"],
  },
  {
    path: "/edit-profile",
    element: <EditProfile />,
    users: ["admin", "support", "business_owner"],
  },
  {
    path: "/users",
    element: <Users />,
    users: ["admin", "support"],
  },
  {
    path: "/business-owner",
    element: <BusinessOwner />,
    users: ["admin"],
  },
  {
    path: "/business-owner-create",
    element: <CreateBusinessOwner />,
    users: ["admin"],
  },
  {
    path: "/review-user",
    element: <ReviewUser />,
    users: ["admin"],
  },
  {
    path: "/review-user-create",
    element: <CreateReviewUser />,
    users: ["admin"],
  },
  {
    path: "/create-user",
    element: <CreateUser />,
    users: ["support"],
  },
  {
    path: "/settings",
    element: <Settings />,
    users: ["admin", "support"],
  },
  {
    path: "/settings",
    element: <BusinessSettings />,
    users: ["business_owner"],
  },
];

export default privateRoutes;