import { lazy } from 'react';
import { useRoutes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import privateRoutes from "./privateRoute";
const Login = lazy(() => import("../pages/auth/Login"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));

const Router = () => {
  const role = useSelector((state) => state?.auth?.currentUser?.role) || null;

  let routes = [];

  if (role) {

    routes.push({
      path: "/",
      element: <Navigate replace to={ role === "admin" || role === "business_owner" ?  "/dashboard" : "/create-user" } />,
    });

    privateRoutes.forEach((route, index) => {
      if (route?.users?.includes(role)) {
        routes.push(route);
      }
    })

    routes.push({
      path: "*",
      element: <Navigate to={ role === "admin" || role === "business_owner" ? "/dashboard" : "/create-user" } />
    })

  } else {
    routes = [
      { path: "/", element: <Login /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/password/reset", element: <ResetPassword /> },
      { path: "*", element: <Navigate to='/' /> },
    ];
  }

  return useRoutes(routes);
};

export default Router;
