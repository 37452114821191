import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6149CD",
    },
    secondary: {
      main: "#F0749B",
    },
    error: { main: "#FF7A7A" },
    info: { main: "#7A86A1" },
    success: { main: "#4DB6AC" },
    warning: { main: "#FEB558" },
    text: {
      primary: "#181D336",
      secondary: "#6C72DF",
    },
  },
  typography: {
    fontFamily: "Manrope",
  },
  shape: {
    borderRadius: 16,
  },
  spacing: 1,

  components: {
    MuiButton: {
      styleOverrides: {
        disableElevation: true,
        root: {
          fontWeight: "700",
          fontSize: "14px",
          boxShadow: "3px 16px 40px #695F9724",
          textTransform: "none",
          maxWidth: "432px",
          width: "100%",
          height: "44px",
        },
        contained: {
          background: "transparent linear-gradient(269deg, #8C72FF 0%, #C057EF 47%, #F0749B 100%)",
          color: "#FFFFFF",
          boxShadow: "3px 16px 40px #695F9724",
          "&.Mui-disabled": {
            background: "#E0E0E0 !important",
          }
        },
        outlined: {
          border: "1px solid #FD6D6D",
          color: "#FD6D6D",
          ":hover": { border: "1px solid #FD6D6D" },
        },
        text: {
          boxShadow: "none",
          ":hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#6149CD",
            color: "white",

            ":hover": {
              backgroundColor: "#6149CD",
            },
          },
        },
      },
    },
  },
});
export default theme;
