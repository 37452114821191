import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import authReducer from "./features/authSlice";
import loadingReducer from "./features/loadingSlice";

const persistConfig = {
  key: "liima_admin_web_v1",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    loading: loadingReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store; 
