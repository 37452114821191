import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
  name: "loading",
  initialState: { isGlobalLoading: false, isLocalLoading: false },
  reducers: {
    setGlobalLoading: (state, { payload }) => {
      state.isGlobalLoading = payload;
    },
    setLocalLoading: (state, { payload }) => {
      state.isLocalLoading = payload;
    },
  },
});

export const { setGlobalLoading, setLocalLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
