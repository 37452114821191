import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import store from "./store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { injectStore } from "./Services/axios";
import Loading from "./components/core/Loader";

injectStore(store);

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={ store }>
    <PersistGate persistor={ persistor }>
      <React.Suspense fallback={ <Loading isLoading={true} /> }>
        <ThemeProvider theme={ theme }>
          <App />
        </ThemeProvider>
      </React.Suspense>
    </PersistGate>
  </Provider>
);
