import axios from "axios";
import { refreshToken } from "./AuthService";
import { logout } from "../store/features/authSlice";
import { setGlobalLoading } from "../store/features/loadingSlice";

let store;
export const injectStore = (_store) => {
  store = _store;
};

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const token = store?.getState()?.auth?.accessToken;
    config.baseURL = "/api/v1";
    config.headers.authorization = `Bearer ${token}`;
    store.dispatch(setGlobalLoading(true));
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    store.dispatch(setGlobalLoading(false));
    return response;
  },

  async (error) => {
    store.dispatch(setGlobalLoading(false));

    if (error.response.status === 401) {
      return await store
        .dispatch(refreshToken())
        .unwrap()
        .then(() => {
          return instance(error.config);
        })
        .catch(() => {
          store.dispatch(logout());
        });
    }

    return Promise.reject(error);
  }
);

export default instance;
