import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "./axios";

export const adminLogin = createAsyncThunk("auth/adminLogin", async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/auth/login`, data);

    const { role } = response?.data?.data.user;

    if (role !== "admin" && role !== "support" && role !== "business_owner") {
      // return rejectWithValue(`You don't have access this system !`);
      // eslint-disable-next-line no-throw-literal
      throw `You don't have access this system !`;
    }

    return response?.data.data;
  } catch (error) {
    // return Promise.reject(error);
    return rejectWithValue(error);
    // return rejectWithValue(error?.response?.data?.message);
  }
});

export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/auth/refresh-token");
      return response.data.data.token;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = async (email) => {
  try {
    const response = await axios.post("/auth/forgot-password", email);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axios.post("/auth/password-reset", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (data, id) => {
  try {
    const response = await axios.post(`/user/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
